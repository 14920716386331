<script setup lang="ts">
// @ts-nocheck
const initializeCurator = () => {
  if (process.client) {
    // Select existing curator css/js files.
    const curatorCss = document.querySelector(`link[href='https://cdn.curator.io/5.0/curator.embed.css']`)
    const curatorJs = document.querySelector(`script[src='https://cdn.curator.io/5.0/curator.embed.js']`)
    const curatorCustomCss = document.querySelector(
      `link[href='https://cdn.curator.io/published-css/2a79a372-253d-4b57-bbb6-becf62fe7947_8w27qqqg.css']`,
    )
    const curatorCustomJs = document.querySelector(
      `script[src='https://cdn.curator.io/published/2a79a372-253d-4b57-bbb6-becf62fe7947_8w27qqqg.js']`,
    )

    // Remove existing curator css/js files.
    if (curatorCss) {
      curatorCss.remove()
    }
    if (curatorJs) {
      curatorJs.remove()
    }
    if (curatorCustomCss) {
      curatorCustomCss.remove()
    }
    if (curatorCustomJs) {
      curatorCustomJs.remove()
    }

    // Initialize curator.
    ;(function () {
      var i,
        e,
        d = document,
        s = 'script'
      i = d.createElement('script')
      i.async = 1
      i.charset = 'UTF-8'
      i.src = 'https://cdn.curator.io/published/2a79a372-253d-4b57-bbb6-becf62fe7947_8w27qqqg.js'
      e = d.getElementsByTagName(s)[0]
      e.parentNode.insertBefore(i, e)
    })()
  }
}
onMounted(() => {
  initializeCurator()
})
</script>
<template>
  <SectionSpecial :title="$t('social.title')" :sub-title="$t('social.subtitle')">
    <div id="curator-feed-careum">
      <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a>
    </div>
  </SectionSpecial>
</template>
<style scoped lang="scss">
#curator-feed-careum {
  margin-top: 24px;
}
:deep(.crt-load-more) {
  font-size: 16px;
}
</style>
